import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/_App/layout'
import Seo from '../../components/_App/seo'
import TopHeader from '../../components/Common/TopHeader'
import Navbar from '../../components/_App/Navbar'
import Footer from '../../components/_App/Footer'
import ProductBanner from '../../components/Products/ProductBanner'
import Why from '../../components/Products/Why'
import What from '../../components/Products/What'
import TalkArea from '../../components/Common/TalkArea'
import FaqArea from '../../components/FAQ/FaqArea'

import Data from '../../components/Data/Data'

const pomFeatureQuery = graphql`
  query GetSingleProductFeatureDetails {
    feature: strapiProductFeature(productId: { eq: "pom" }) {
      title
      helpText
      featureCard {
        icon
        shortDesc
        title
      }
    }
  }
`

const POM = () => {
  const {
    feature: { helpText, title, featureCard },
  } = useStaticQuery(pomFeatureQuery)

  return (
    <Layout>
      <Seo
        title="POM"
        description="Effortlessly create and manage Purchase Orders (PO), track PO status, manage vendors & their SKUs, and get accurate insights into your POs."
        image="https://res.cloudinary.com/innoage/image/upload/c_scale,w_1024/v1684663575/pom-web_sahscy.png"
      />

      <TopHeader />

      <Navbar />

      <ProductBanner
        image="https://res.cloudinary.com/innoage/image/upload/c_scale,w_1024/v1684663575/pom-web_sahscy.png"
        name="POM - B2B Solution"
        description="Create and Manage Purchase Order, vendor management, cost negotiation, insights and analytics, approval workflows, production tracking, shipment tracking, real-time collaboration, and mobile accessibility."
      />

      <Why why={Data.whyPOM} />

      <What helpText={helpText} title={title} featureCard={featureCard} />

      <TalkArea />

      <FaqArea
        description="We at InnoAge always believe in Transparency with our clients & employees, please check
            list of frequently asked questions. Feel free to ask if this doesn't help."
        faq1={Data.faqpom1}
        faq2={Data.faqpom2}
      />

      <Footer />
    </Layout>
  )
}

export default POM
